.react-tel-input .form-control {
    width: 100% !important;
    height: 40px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 14px;
}

.react-tel-input .flag-dropdown { 
    background-color: #ffffff !important;
}

.react-tel-input .form-control .error-input {
    border: 1px solid red !important;
}

/* .react-tel-input .flag-dropdown .error-input { 
    border: 1px solid red !important;
} */

.form-control:focus {
    box-shadow: none !important
}