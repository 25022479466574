
.input_error,
.input_error:focus {
  border: 1px solid #e83147 !important;
}

.error {
  color: #e83147;
  font-size: 13px;
}
.mobile-field .react-tel-input .form-control{
    width:95% !important
}