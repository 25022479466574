.CookiePop {
  background-color: #141b2a;
  border-radius: 20px;
  border: 1px #f6f9ff;
  position: fixed;
  bottom: 40px;
  z-index: 100000;
  width: 96%;
  left: 30px;
  color: #ffffff;
}

