.subscriptionCard:hover {
  transition: all 0.3s;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  transform: scale(1.01);
  border: 1px solid #cccccc;
}

.model-bg {
  background-color: rgb(0, 0, 0) !important;
  opacity: 0.7 !important;
}

.accordion-button {
  background: transparent !important;
  border: none !important;
  color: black !important;
  border-radius: 0 !important;
  outline: none !important;
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accordion-body {
  padding-top: 5px !important;
}

.accordion {
  background: transparent !important;
}

.accordion-header {
  background: transparent !important;
}

.accordion-item {
  border: none !important;
  border-bottom: 2px solid #e2e3e5 !important;
}

.accordion-button.collapsed::after {
  background: url("https://unpkg.com/ionicons@7.1.0/dist/svg/add-circle-outline.svg") !important;
}

.accordion-button:not(.collapsed)::after {
  background: url("https://unpkg.com/ionicons@7.1.0/dist/svg/remove-circle-outline.svg") !important;
}

.pending-spin-loader {
  display: inline;
  width: 3.3rem;
  height: 3.3rem;
  /* margin-right: 0.5rem; */
  color: #edf2f7;
  animation: loaderSpin 1s linear infinite;
  color: #adb1b6;
  fill:  #0F337C ;
}

@keyframes loaderSpin {
  to {
    transform: rotate(360deg);
  }
}

.form-switch .form-check-input{
  width: 2.5rem !important;
  height: 1.5rem !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.popup-size {
  width: 500px !important;
}

@media (max-width: 500px) {
  .popup-size {
    width: 95% !important;
  }
  .logo-responsive {
    width: 100px;
  }
  
}



body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* Cookie Consent Banner Styles */
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.cookie-consent-banner p {
  margin: 0;
  padding-right: 20px;
}

.cookie-consent-banner button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

#ele-304 {
  display: none !important;
}